import * as React from "react";
import { Layout } from "app/components/layout";
import { Header1 } from "app/components/header";
import { Footer1 } from "app/layouts/footer1";

const Accessibility: React.FC = () => (
  <Layout>
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12 font-sans">
      <Header1 />
      <div className="max-w-3xl m-9">
        <h1>Accessibility</h1>
        <h2>Accessibility</h2>
        <p>
          Qameta Software s.r.o. recognizes the importance of making information, services and job
          opportunities, accessible to all. Hence, we strive to uphold the principles and best
          practices of internationally recognized accessibility standards (WCAG, BS8878 & EN301549).
          While we aim to comply with these guidelines, we understand the ever-evolving industry
          that is IT, therefore our core objective is to provide a website that is accessible to the
          widest possible audience, as to ensure an accessible experience on our site.
        </p>
        <h2>Our Policy & Standards Compliance</h2>
        <p>
          Many Internet users with disabilities find websites difficult or even impossible to use
          simply because of the way they are designed. We have therefore designed our website to be
          as accessible as possible and to be compatible with the types of adaptive and assistive
          technology used by people with disabilities, including screen readers and screen
          magnifiers.
        </p>
        <p>
          All of the pages contained within this website comply with the Accessibility Guidelines or
          General Principles of Accessible Design laid down by the WAI (Web Accessibility
          Initiative) Working Group of the W3C. Additionally, the presentation layer has been
          created in accordance with the W3C Recommendation on Cascade Style Sheets, Level 2 (CSS2).
        </p>
        <p>
          Specifically, this website aims to conform to Web Content Accessibility Guidelines 1.0,
          Level AA.
        </p>
        <h2>Accessibility Features</h2>
        <p>Our website contains the following accessibility features:</p>
        <ul>
          <li>A flexible screen layout (which resizes depending on common screen sizes).</li>
          <li>Search functionality.</li>
          <li>Resizable text.</li>
          <li>
            Links have meaningful text, which outlines the purpose of links implemented, internal
            links don’t open in a new tab, whilst external links open in a new tab.
          </li>
          <li>
            The visual design of the website has been created using cascading style sheets (CSS).
          </li>
          <li></li>
        </ul>
        <h2>How do I resize fonts?</h2>
        <p>
          The font size present on the website can be altered to your preference through your
          browser.
        </p>
        <ul>
          <li>
            In Internet Explorer, press “Alt”, select “View”, then “Text Size”, and then select your
            preferred text size.
          </li>
          <li>
            In Chrome select “Settings”, then “Appearance” and then select your preferred text size.
          </li>
          <li>
            In Firefox select “Options”, then “Content” and then select your preferred text size
            within the “Font & Colour” section.
          </li>
        </ul>
        <h2>Feedback</h2>
        <p>
          We are continually implementing improvements to enhance the accessibility of the website.
          In the meantime, should you experience any difficulty in accessing the sections of the
          website, please don’t hesitate to contact us.
        </p>
      </div>
      <Footer1 />
    </div>
  </Layout>
);

export default Accessibility;
